<template>
  <div>
    <b-tabs
      v-model="tabIndex"
      content-class="tab-autenticacao"
      class="abas-formulario"
      @activate-tab="tabActivated"
    >
      <b-tab title="Ficha cadastral">
        <Ficha ref="fichaRef" :form="form" @submitted="mudarAba(1)" />
      </b-tab>
      <b-tab title="Endereço">
        <Endereco ref="enderecoRef" :form="form" @submitted="mudarAba(2)" />
      </b-tab>
      <b-tab title="Contato">
        <Contato ref="contatoRef" :form="form" @submitted="mudarAba(3)" />
      </b-tab>
      <b-tab title="App Client">
        <ClientApp ref="clientAppRef" :form="form" @submitted="mudarAba(4)" />
      </b-tab>
      <b-tab title="Conta Bancária">
        <ContaBancaria
          ref="contaBancariaRef"
          :form="form"
          @salvar="salvar"
          @manter="manter"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Ficha from "./Ficha.vue";
import Contato from "./Contato.vue";
import Endereco from "./Endereco.vue";
import ContaBancaria from "./ContaBancaria.vue";
import ClientApp from "./ClientApp.vue";

import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import EstabelecimentoService from "@/common/services/estabelecimentos/estabelecimentos.service";
import cloneDeep from "lodash.clonedeep";
import helpers from "../../common/utils/helpers";

export default {
  name: "Formulario",
  props: ["id"],
  components: {
    Ficha,
    Contato,
    Endereco,
    ContaBancaria,
    ClientApp,
  },
  data() {
    return {
      tabIndex: 0,
      form: {
        cpfCnpj: null,
        razaoSocial: null,
        nomeFantasia: null,
        arquivoLogo: null,
        email: null,
        codigoMCC: null,
        estabelecimentoTaxasProcessamentoAntecipacao: {
          taxaDebito: 0,
          taxaCredito: 0,
          taxaParceladoEntre2e6: 0,
          taxaParceladoEntre7e12: 0,
          taxaParceladoEntre13e21: 0,
          taxaAntecipacaoMensal: 0,
        },
        endereco: {
          cep: null,
          logradouro: null,
          numero: null,
          complemento: null,
          bairro: null,
          cidade: null,
          uf: null,
          pais: null,
        },
        baseJuros: "JurosProRata",
        rav: true,
        multiplosCartoes: true,
        bloquearNumeroParcelas: false,
        exibirEndereco: false,
        utilizaTEF: true,
        envioUnicoWebhook: false,
        reenvioWebhookLiquidacao: false,
        pagamentoInternacional: false,
        cancelamento: false,
        enviarStatusEmProgressoWebhook: false,
        contaBancarias: [],
        contatos: [],
        convenios: [],
        usuarioIds: [],
        clientApps: [],
      },
    };
  },
  mounted() {
    this.$store.dispatch(DEFINIR_BREADCRUMB, [
      { titulo: this.$t("MENU.ESTABELECIMENTOS") },
    ]);
    if (this.id) {
      this.buscar();
    }
    this.validarAbas();
  },
  methods: {
    tabActivated(newTabIndex, oldTabIndex, event) {
      if (!this.validarAbas(false, newTabIndex)) return event.preventDefault();
      this.tabIndex = newTabIndex;
    },
    validarAbas: function (escondido = true, index) {
      if (!this.$refs.fichaRef.validarFormulario("", escondido) && index > 0) {
        this.tabIndex = 0;
        return false;
      }
      if (
        !this.$refs.enderecoRef.validarFormulario("", escondido) &&
        index > 1
      ) {
        this.tabIndex = 1;
        return false;
      }
      return true;
    },
    mudarAba: function (index) {
      if (this.validarAbas()) {
        this.tabIndex = index;
      }
    },
    buscar: function () {
      this.$store.dispatch(START_LOADING);
      EstabelecimentoService.buscar(this.id)
        .then((res) => {
          var dados = res.data.data;
          this.form = dados;
        })
        .catch(() => {
          this.$router.push({ name: "estabelecimentos" });
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
          this.validarAbas();
        });
    },
    salvar: function () {
      this.confirmar(
        this.$t(`ESTABELECIMENTOS.TITULO_SALVAR`),
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then((confirmado) => {
        if (!confirmado) return;

        //if (!this.validarAbas(false)) return false;

        let form = cloneDeep(this.form);
        form.endereco.cep = helpers.removerMascara(form.endereco.cep);

        this.$store.dispatch(START_LOADING);
        EstabelecimentoService.salvar(form)
          .then(() => {
            this.alertaSucesso(
              this.$t("ESTABELECIMENTOS.MENSAGEM_SUCESSO")
            ).then(() => {
              this.$router.push({ name: "estabelecimentos" });
            });
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      });
    },
    manter: function () {
      this.confirmar(
        this.$t(`ESTABELECIMENTOS.TITULO_SALVAR`),
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then((confirmado) => {
        if (!confirmado) return;

        //if (!this.validarAbas(false)) return false;

        let form = cloneDeep(this.form);
        form.endereco.cep = helpers.removerMascara(form.endereco.cep);

        this.$store.dispatch(START_LOADING);
        EstabelecimentoService.salvar(form)
          .then(() => {
            var novo = true;
            if (this.id) novo = false;
            this.alertaSucesso(
              this.$t("ESTABELECIMENTOS.MENSAGEM_SUCESSO")
            ).then(() => {
              if (novo) this.$router.push({ name: "estabelecimentos" });
              else {
                this.atualizar();
              }
            });
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      });
    },
    atualizar: function () {
      this.$store.dispatch(START_LOADING);
      EstabelecimentoService.buscar(this.id)
        .then((res) => {
          this.form = res.data.data;
        })
        .catch(() => {
          this.$router.push({ name: "estabelecimentos" });
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
          this.validarAbas();
        });
    },
  },
};
</script>
